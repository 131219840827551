import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { ControllerActions, createActions } from '../../actions/actions';
import {
  ActionsContext,
  createActionsContext,
} from '../../core/actionsContext/contextFactory';
import { createControlledComponent } from '../../core/ControlledComponent';
import {
  ApplicationState,
  ApplicationStatus,
  CheckoutSlotProps,
} from '../../types';
import { EventNames, FedopsLogger } from '../../core/fedops';

const checkoutSlotProps: Partial<CheckoutSlotProps> = {};
let controllerActions: ControllerActions;

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const initialize = async () => {
    const context = createActionsContext({ flowAPI });

    const { onStateChange, actions } = await createControlledComponent<
      ApplicationState,
      ControllerActions,
      ActionsContext
    >({
      context,
      actionsFactory: createActions,
      initialState: {
        status: ApplicationStatus.INITIALIZING,
        environmentModes: context.api.getEnvironmentModes(),
      },
    });

    controllerActions = actions;

    if (!context.api.isSSR()) {
      onStateChange((state) => {
        context?.biLogger?.update(state);
      });
    }
  };

  const initializeCheckoutSlotProps = (
    key: keyof CheckoutSlotProps,
    value: any,
  ) => {
    checkoutSlotProps[key] = value;

    if (
      checkoutSlotProps.checkoutId &&
      checkoutSlotProps.refreshCheckoutCallback
    ) {
      controllerActions.setCheckoutSlotProps({
        checkoutId: checkoutSlotProps.checkoutId,
        refreshCheckoutCallback: checkoutSlotProps.refreshCheckoutCallback,
      });
    }
  };

  return {
    async pageReady() {
      const fedopsLogger = new FedopsLogger(flowAPI);
      fedopsLogger.interactionStarted(EventNames.PageLoaded);

      await initialize();

      fedopsLogger.interactionEnded(EventNames.PageLoaded);
    },
    exports: () => {
      return {
        set checkoutId(checkoutId: string) {
          initializeCheckoutSlotProps('checkoutId', checkoutId);
        },
        onRefreshCheckout(cb: () => void) {
          initializeCheckoutSlotProps('refreshCheckoutCallback', cb);
        },
      };
    },
  };
};

export default createController;
