import { TipSettings } from '@wix/ambassador-tips-settings-v1-tip-settings/types';
import {
  AmountType,
  ApplicationState,
  ApplicationStatus,
  Preset,
  PresetInfo,
  SelectedAmountStatuses,
} from '../../types';
import { GetCheckoutResponse } from '@wix/ambassador-ecom-v1-checkout/types';
import { getSelectedPreset, presetMapper } from '../presets';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const createInitialState = ({
  tipSettings,
  checkoutInfo,
  flowAPI,
}: {
  tipSettings?: TipSettings;
  checkoutInfo: GetCheckoutResponse;
  flowAPI: ControllerFlowAPI;
}): Partial<ApplicationState> => {
  let presets: Preset[] = [];

  if (tipSettings?.allowCustomerTip) {
    presets = presetMapper({
      settings: tipSettings,
      flowAPI,
      currency: checkoutInfo.checkout?.currency!,
    });
  }

  const presetInfo: PresetInfo = { presets };

  const selectedPreset = getSelectedPreset(presets);

  return {
    status: ApplicationStatus.IDLE,
    checkoutInfo,
    presetInfo,
    selectedAmount: {
      amount: selectedPreset?.amount || '0',
      status: SelectedAmountStatuses.IDLE,
      type: selectedPreset?.amountType || AmountType.Fixed,
    },
  };
};
