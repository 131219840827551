import { CheckoutSlotProps } from '../../types';
import { CreateActionParams } from '../actions';

export type SetCheckoutSlotProps = (
  checkoutSlotProps: CheckoutSlotProps,
) => void;

export function createSetCheckoutSlotPropsAction({
  getControllerState,
}: CreateActionParams): SetCheckoutSlotProps {
  return async (checkoutSlotProps) => {
    const [, setState] = getControllerState();

    setState({
      checkoutSlotProps,
    });
  };
}
