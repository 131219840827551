import { SelectedAmount, SelectedAmountStatuses } from '../../types';
import { CreateActionParams } from '../actions';
import { EventNames } from '../../core/fedops';

export type SelectTipAmount = ({
  presetId,
  selectedAmount,
}: {
  presetId: string;
  selectedAmount: SelectedAmount;
}) => void;

export function createSelectTipAmountAction({
  getControllerState,
  context,
}: CreateActionParams): SelectTipAmount {
  return async ({ presetId, selectedAmount }) => {
    const { fedopsLogger, flowAPI } = context;
    const { translations } = flowAPI;
    fedopsLogger?.interactionStarted(EventNames.SelectTipAmount);

    const [state, setState] = getControllerState();
    const { presets } = state.presetInfo!;
    const newPresets = presets?.map((preset) => ({
      ...preset,
      isSelected: preset.id === presetId,
    }));

    setState({
      presetInfo: { presets: newPresets },
      selectedAmount: {
        ...selectedAmount,
        status: SelectedAmountStatuses.IN_PROGRESS,
      },
      errorMessage: undefined!,
    });

    try {
      // TODO: Call to update api
      await new Promise((resolve) => setTimeout(resolve, 2000));

      state.checkoutSlotProps?.refreshCheckoutCallback();

      setTimeout(() => {
        setState({
          selectedAmount: {
            ...selectedAmount,
            status: SelectedAmountStatuses.SUCCESS,
          },
        });
      }, 1500);

      fedopsLogger?.interactionEnded(EventNames.SelectTipAmount);
    } catch (e) {
      setState({
        selectedAmount: {
          ...selectedAmount,
          status: SelectedAmountStatuses.FAILURE,
        },
        errorMessage: translations.t(
          'app.wix-tips.widget.apply-tip-error-message',
        ),
      });
      setTimeout(() => {
        setState({
          selectedAmount: {
            ...selectedAmount,
            status: SelectedAmountStatuses.IDLE,
          },
        });
      }, 2000);
    }
  };
}
