import { CreateActionParams } from '../actions';
import { EventNames } from '../../core/fedops';
import { createInitialState } from '../../utils';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  context,
  getControllerState,
}: CreateActionParams): InitializeWidget {
  return async () => {
    const { fedopsLogger, api, flowAPI } = context;
    fedopsLogger?.interactionStarted(EventNames.WidgetInitialized);
    const [state, setState] = getControllerState();
    const { checkoutSlotProps } = state;

    if (!checkoutSlotProps) {
      throw new Error('Checkout slot props are missing');
    }

    const [checkoutInfo, tipSettings] = await Promise.all([
      api.getCheckoutInfo({
        checkoutId: checkoutSlotProps.checkoutId,
      }),
      api.getTipSettings({}),
    ]);

    const initialState = createInitialState({
      tipSettings,
      checkoutInfo,
      flowAPI,
    });

    setState(initialState);

    fedopsLogger?.interactionEnded(EventNames.WidgetInitialized);
  };
}
